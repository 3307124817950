export function getIssues (component) {
  return [
    {
      code: "multi_instances",
      label: component.$pgettext("*/*/Error.Message", "Multiple running instances"),
      description: component.$pgettext("*/*/Error.Message", "Multiple instances of Alta Call TSM are running at the same time."),
      solution: [
        component.$pgettext("*/*/Solution", "Please keep only one instance, and close or stop the other ones.")
      ],
      hints: [
        component.$pgettext("*/*/Hint", "To avoid any audio issue, it is better to avoid having multiple users logged in the same machine.")
      ]
    },
    {
      code: "audio_api_none",
      label: component.$pgettext("*/*/Error.Message", "Cannot run selected audio setup"),
      description: component.$pgettext("*/*/Error.Message", "Alta Call TSM cannot run with the currently configured audio setup."),
      solution: [],
      hints: [
        component.$pgettext("*/*/Hint", "Does Alta Call TSM have the rights to access the audio devices?"),
        component.$pgettext("*/*/Hint", "Is there any anti-virus blocking the access? If yes, please add Alta Call TSM in the Trusted Applications (or similar, depending on your anti-virus software) and restart Alta Call TSM."),
      ]
    },
    {
      code: "audio_devices_none",
      label: component.$pgettext("*/*/Error.Message", "Cannot access audio devices"),
      description: component.$pgettext("*/*/Error.Message", "Alta Call TSM cannot access any audio devices."),
      solution: [],
      hints: [
        component.$pgettext("*/*/Hint", "Does Alta Call TSM have the rights to access the audio devices?"),
        component.$pgettext("*/*/Hint", "Is there any anti-virus blocking the access? If yes, please add Alta Call TSM in the Trusted Applications (or similar, depending on your anti-virus software) and restart Alta Call TSM."),
      ]
    },
    {
      code: "audio_setup_failed",
      label: component.$pgettext("*/*/Error.Message", "Cannot access audio"),
      description: component.$pgettext("*/*/Error.Message", "Alta Call TSM cannot access audio on this machine."),
      solution: [
        component.$pgettext("*/*/Solution", "For machines with high uptime, it is recommended to simply restart the machine.")
      ],
      hints: [
        component.$pgettext("*/*/Hint", "Did another software took exclusivity over an audio device and prevent Alta Call TSM to use it?"),
        component.$pgettext("*/*/Hint", "It is possible that the audio stream has been broken by another session openned on the same machine. It is recommanded to either close all sessions and re-open the one you want to use, or simply restart the machine."),
        component.$pgettext("*/*/Hint", "Is the selected sampling rate incompatible with an audio device?"),
      ]
    },
    {
      code: "audio_disconnected",
      label: component.$pgettext("*/*/Error.Message", "No audio application"),
      description: component.$pgettext("*/*/Error.Message", "Alta Call TSM is not connected to an audio application."),
      solution: [
        component.$pgettext("*/*/Solution", "Please select the virtual audio device (ex. VoiceMeeter Output) as capture/recording device in your softphone in order to use Alta Call TSM's transformation.")
      ],
    },
    {
      code: "version_notsupported",
      label: component.$pgettext("*/*/Error.Message", "Unsupported version of Alta Call TSM"),
      description: component.$pgettext("*/*/Error.Message", "Alta Call TSM is running a version that isn't supported anymore."),
      solution: [
        component.$pgettext("*/*/Solution", "Please download the newest version of Alta Call TSM from your community space and re-install it on these machines.")
      ],
    },
    {
      code: "audio_stopped",
      label: component.$pgettext("*/*/Error.Message", "Audio stream stopped"),
      description: component.$pgettext("*/*/Error.Message", "The audio stream is stopped, either because it has been requested or because it has never been configured."),
      solution: [
        component.$pgettext("*/*/Solution", "Please configure it at least once and apply the setup.")
      ],
    },
    {
      code: "audio_device_lost",
      label: component.$pgettext("*/*/Error.Message", "Audio device lost"),
      description: component.$pgettext("*/*/Error.Message", "The audio device used is lost. Surely disconnected."),
      solution: [
        component.$pgettext("*/*/Solution", "Please plug back the audio device, or chose another one.")
      ],
    },
    {
      code: "audio_crashed",
      label: component.$pgettext("*/*/Error.Message", "Audio stream crashed"),
      description: component.$pgettext("*/*/Error.Message", "The audio stream crashed for unknown reason."),
      solution: [
        component.$pgettext("*/*/Solution", "Another audio software might have asked exclusivity on the selected audio devices. Please, disallow exclusivity usage of audio device or simply close the other audio software."),
        component.$pgettext("*/*/Solution", "Maybe a security software blocked access to audio devices. Please check your anti-virus software and authorize audio access to Alta Call TSM. Ex. Add Alta Call TSM in the Trusted Applications (or similar, depending on your anti-virus software) and restart Alta Call TSM."),
      ],
    },
  ]
}
export function getIssueHelp (component, issue) {
  const matching = getIssues(component).filter((i) => {
    return i.code == issue.code
  })
  const fallback = {
    code: issue.code,
    label: component.$pgettext("*/*/Solution", "Unknown issue"),
    hints: [],
    solution: [
      component.$pgettext("*/*/Solution", "Please contact our support.")
    ]
  }
  return matching.length > 0 ? {...fallback, ...matching[0]} : fallback
}