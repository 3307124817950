
<template>
  <div class="main">
    <section class="bordered padded" v-if="!id">
      <form @submit.prevent>
        <div class="stackable row">

          <div class="field">
            <label for="search">
              <translate translate-context="*/*/*/Verb">Search</translate>
            </label>
            <input id="search" v-model="filters.q" :placeholder="searchPlaceholder" :title="searchPlaceholder">
          </div>
          <div class="field">
            <label for="status">
              <translate translate-context="*/*/*/Noun">Status</translate>
            </label>
            <select id="status" v-model="filters.online">
              <option value="">
                <translate translate-context="*/Field/Value">Any</translate>
              </option>
              <option value="true"><translate translate-context="*/*/Status">Online</translate></option>
              <option value="false"><translate translate-context="*/*/Status">Offline</translate></option>
            </select>
          </div>
          <div class="field">
            <label for="version"><translate translate-context="*/*/*">Version</translate></label>
            <select id="version" v-model="filters.version">
              <option value=""><translate translate-context="*/Field/Value">Any</translate></option>
              <option
                :value="version"
                v-for="version in meta.versions"
                :key="version.id">{{ version }}</option>
            </select>
          </div>
        </div>
        <div class="stackable row">
          <div class="field">
            <label for="error"><translate translate-context="*/*/*">Issues</translate></label>
            <select id="error" v-model="filters.error">
              <option value=""><translate translate-context="*/*/*">Any issue</translate></option>
              <option :value="issue" v-for="issue in meta.issues" :key="issue">{{ issue }}</option>
            </select>
          </div>
          <div class="field">
            <label for="label"><translate translate-context="*/*/*/Noun">Label</translate></label>
            <select id="label" v-model="filters.label">
              <option value=""><translate translate-context="*/Field/Value">Any</translate></option>
              <option :value="label" v-for="label in meta.labels" :key="label">{{ label }}</option>
            </select>
          </div>
          <div class="field">
            <label for="abtest"><translate translate-context="*/*/*">A/B Test</translate></label>
            <select id="abtest" v-model="filters.abtest">
              <option value=""><translate translate-context="*/Field/Value">All users</translate></option>
              <option value="true"><translate translate-context="*/*/*">Any test</translate></option>
              <option value="false"><translate translate-context="*/*/*">No test</translate></option>
              <option :value="abtest.id" v-for="abtest in meta.abtests" :key="abtest.id">{{ abtest.name }}</option>
            </select>
          </div>
        </div>
      </form>
    </section>
    <section class="v-spaced bordered padded" v-if="isLoading">
      <loading-area :is-loading="isLoading">
        <span><translate translate-context="*/*/Loader">Loading issues…</translate></span>
      </loading-area>
    </section>
    <section class="v-spaced bordered padded" v-else-if="results && results.count === 0">
      <empty-state>
        <template v-slot:controls >
          · <a href="" v-if="hasFilters" @click.prevent="filters = {...initialFilters}">
            <translate translate-context="*/*/Form.Link/Action">Reset filters</translate>
          </a>
        </template>
      </empty-state>
    </section>
    <section class="v-spaced bordered padded" v-else-if="results">
      <header class="controls mb-regular">
        <h2>
          <translate
            :translate-params="{count: results.count}"
            translate-plural="%{ count } matching errors"
            :translate-n="results.count"
            translate-context="*/*/Title">
            %{ count } matching errors
          </translate>
        </h2>
        <a href="" v-if="hasFilters" @click.prevent="filters = {...initialFilters}">
          <translate translate-context="*/*/Form.Link/Action">Reset filters</translate>
        </a>
      </header>
      <table>
        <thead>
          <th>
            <sort-link @updated="filters.o = $event" :current-sort="filters.o" value="name">
              <translate translate-context="*/*/*">User</translate>
            </sort-link>
          </th>
          <th>
            <sort-link @updated="filters.o = $event" :current-sort="filters.o" value="first_seen">
              <translate translate-context="*/*/*">First seen</translate>
            </sort-link>
          </th>
          <th>
            <sort-link @updated="filters.o = $event" :current-sort="filters.o" value="last_update">
              <translate translate-context="*/*/*">Last seen</translate>
            </sort-link>
          </th>
          <th>
            <sort-link @updated="filters.o = $event" :current-sort="filters.o" value="local_ip">
              <translate translate-context="*/*/*">Local IP</translate>
            </sort-link>
          </th>
          <th>
            <translate translate-context="*/*/*">Details and solution</translate>
          </th>
        </thead>
        <tbody>
          <tr v-for="obj in results.results" :key="`${obj.last_update}`">
            <td class="monospace" v-if="obj.machine.users.length > 0">
              <router-link :to="{name: 'dashboard.users.detail', params: {id: obj.machine.users[0].session_hash, group: $store.state.selectedGroupId}}">
                <template v-if="obj.machine.users[0].name">
                  {{ obj.machine.users[0].name }}
                </template>
                <template v-else>
                  {{ obj.machine.users[0].session_hash.slice(0, 8) }}
                </template>
              </router-link>
            </td>
            <td class="monospace" v-else>
              <router-link :to="{name: 'dashboard.machines.detail', params: {id: obj.machine.name, group: $store.state.selectedGroupId}}">
                {{ obj.machine.name.slice(0, 8) }}
              </router-link>
            </td>
            <td>
              {{ ago(obj.first_update) }}
            </td>
            <td>
              {{ ago(obj.last_update) }}
            </td>
            <td class="monospace">
              {{ obj.machine.ip.local }}
            </td>
            <td>
              <a href="" @click.prevent="selectedIssue = obj; showDetailModal = true">
                {{ getIssueHelp(obj).label }}
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <app-modal
        v-if="selectedIssue && showDetailModal"
        :submit-action="true"
        @close="showDetailModal = false; selectedIssue = null">
        <template v-slot:header>
          <h3>
            {{ getIssueHelp(selectedIssue).label }}
          </h3>
        </template>
        <p>
          {{ getIssueHelp(selectedIssue).description }}
        </p>
        <template v-if="getIssueHelp(selectedIssue).solution.length > 0">
          <h5>
            <strong v-translate translate-context="*/*/*">Solution</strong>
          </h5>
          <ul>
            <li v-for="s in getIssueHelp(selectedIssue).solution" :key="s">
              {{ s }}
            </li>
          </ul>
        </template>
        <template v-if="getIssueHelp(selectedIssue).hints.length > 0">
          <h5>
            <strong v-translate translate-context="*/*/*">Hints</strong>
          </h5>
          <ul>
            <li v-for="h in getIssueHelp(selectedIssue).hints" :key="h">
              {{ h }}
            </li>
          </ul>
        </template>
        <table class="no-headers mt-large">
          <tbody>
            <tr>
              <td><translate translate-context="*/*/*">Code</translate></td>
              <td>{{ selectedIssue.code }}</td>
            </tr>
            <tr>
              <td><translate translate-context="*/*/*">First seen</translate></td>
              <td v-if="selectedIssue.first_update">
                <time class="discrete" :datetime="selectedIssue.first_update" :title="selectedIssue.first_update">
                  {{ ago(selectedIssue.first_update) }}
                </time>
              </td>
              <td v-else>N/A</td>
            </tr>
            <tr>
              <td><translate translate-context="*/*/*">Last seen</translate></td>
              <td v-if="selectedIssue.last_update">
                <time class="discrete" :datetime="selectedIssue.last_update" :title="selectedIssue.last_update">
                  {{ ago(selectedIssue.last_update) }}
                </time>
              </td>
              <td v-else>N/A</td>
            </tr>
          </tbody>
        </table>
        <template v-slot:action-button>
          <router-link
            class="button"
            v-if="selectedIssue.machine.users.length > 0"
            :to="{name: 'dashboard.users.detail', params: {id: selectedIssue.machine.users[0].session_hash, group: $store.state.selectedGroupId}}">
            <translate translate-context="*/*/*">Edit configuration…</translate>
          </router-link>
        </template>
      </app-modal>
    </section>
    <pagination
      v-if="results && results.count > pageSize"
      @page-changed="filters.page = $event"
      :current="filters.page"
      :paginate-by="pageSize"
      :total="results.count"
      ></pagination>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import isEqual from 'lodash/isEqual'
import forOwn from 'lodash/forOwn'
import http from '@/http'

import Pagination from '@/components/Pagination'
import SortLink from '@/components/SortLink'
import { ago } from '@/time'

import {getIssueHelp} from '@/copy'

function toQueryString (obj) {
  let d = {}
  forOwn(obj, (val, key) => {
    if (val) {
      d[key] = val
    }
  })
  return d
}

const initialFilters = {
  version: "",
  error: "",
  online: "",
  o: '-last_update',
  page: 1,
  q: '',
  label: "",
  abtest: "",
}

export default {
  props: {
    id: {required: false},
    group: {},
  },
  components: {
    Pagination,
    SortLink,
  },
  data () {
    let f = {
      errors: {},
      isLoading: false,
      results: null,
      meta: {countries: [], transformations: [], labels: []},
      filters: {...initialFilters, ...this.$route.query},
      initialFilters: initialFilters,
      pageSize: 50,
      showDetailModal: false,
      selectedIssue: null,
      ago,
    }
    return f
  },
  async created () {    
    let fetch = this.fetchData(this.filters)
    this.meta = (await http.get("it/meta", {params: {group: this.group}})).data
    await fetch
  },
  computed: {
    hasFilters () {
      return !isEqual(initialFilters, this.filters)
    },
    allFilters () {
      return {...this.filters}
    },
    searchPlaceholder () {
      return this.$pgettext('*/*/Search.Placeholder', 'IP, Agent name…')
    },
  },
  methods: {
    getIssueHelp (issue) {
      return getIssueHelp(this, issue)
    },
    async fetchData(filters) {
      let response
      this.isLoading = true
      this.errors = {}
      this.results = null
      let params = {...filters, page_size: this.pageSize, group: this.group}
      try {
        response = await http.get('it/issues', {params})
        this.results = response.data
      } catch (e) {
        this.errors = e.backendErrors
      } finally {
        this.isLoading = false
      }
    },
    async refresh () {
      this.expandedRow = null
      if (!this.id) {
        this.$router.push({query: toQueryString(this.filters)})
      }
      window.scrollTo(0, 0);
      await this.fetchData(this.filters)
    }
  },
  watch: {
    allFilters: {
      deep: true,
      handler: debounce(
        async function (n, o) {
          if (n.page === o.page) {
            this.filters.page = 1
          }
          await this.refresh()
        }, 250
      )
    },
  }
}
</script>
