<template>
  <form action="" id="abtestform" @submit.prevent="save">
    <div class="success message" role="alert" v-if="showSuccessMessage">
      <p>
        <translate translate-context="*/*/Form.Message">
          Configuration saved.
        </translate>
      </p>
    </div>
    <field-errors :errors="errors.nonFieldErrors" />
    <h3>
      <translate translate-context="*/*/*">General information</translate>
    </h3>
    <div class="stackable row">
      <div class="required field">
        <label for="name">
          <translate translate-context="*/*/*/Noun">Name</translate>
        </label>
        <field-errors :errors="errors.fieldErrors" field="name" />
        <input
          type="text"
          name="name"
          id="name"
          placeholder="My Campaign"
          :disabled="data.id"
          v-model="data.name">
      </div>
    </div>
    <hr class="hidden">
    <h3>
      <translate translate-context="*/*/*">Contact</translate>
    </h3>
    <div class="stackable row">
      <div class="required field">
        <label for="report_email">
          <translate translate-context="*/*/*/Noun">Reports email</translate>
        </label>
        <field-errors :errors="errors.fieldErrors" field="report_email" />
        <input type="tex" name="report_email" id="report_email" v-model="data.report_email">
        <p class="discrete">
          <translate translate-context="*/*/*">Fill in the email address of the person who will receive daily technical reports and fix potential configuration issues. Multiple, coma-separated values are allowed.</translate>
        </p>
      </div>
      <div class="required field">
        <label for="data_email">
          <translate translate-context="*/*/*/Noun">Data email</translate>
        </label>
        <field-errors :errors="errors.fieldErrors" field="data_email" />
        <input type="tex" name="data_email" id="data_email" v-model="data.data_email">
        <p class="discrete">
          <translate translate-context="*/*/*">Fill in the email address of the person who will update performance data and send them to Alta Call TSM and a weekly basis. Multiple, coma-separated values are allowed.</translate>
        </p>
      </div>
    </div>
    <hr class="hidden">
    <div class="stackable row">
      <div class="required field">
        <label for="efx_email">
          <translate translate-context="*/*/*/Noun">Effects email</translate>
        </label>
        <field-errors :errors="errors.fieldErrors" field="efx_email" />
        <input type="tex" name="efx_email" id="efx_email" v-model="data.efx_email">
        <p class="discrete">
          <translate translate-context="*/*/*">Fill in the email address of the person who will receive a daily report of Alta Call TSM applied effects. Multiple, coma-separated values are allowed.</translate>
        </p>
      </div>
      <div class="field">
        <label for="kpis">
          <translate translate-context="*/*/*/Noun">KPIs</translate>
        </label>
        <field-errors :errors="errors.fieldErrors" field="kpis" />
        <list-field
          name="kpis"
          id="kpis"
          v-model="data.kpis"
          :disabled="data.id"
          placeholder="sph, satcli" />
        <p class="discrete">
          <translate translate-context="*/*/*">Performance indicators to analyze.</translate>
        </p>
      </div>
    </div>
    <hr class="hidden">
    <h3>
      <translate translate-context="*/*/*">Dates</translate>
    </h3>
    <div class="stackable row">
      <div class="required field">
        <label for="start">
          <translate translate-context="*/*/*/Noun">Start date</translate>
        </label>
        <field-errors :errors="errors.fieldErrors" field="start" />
        <input :disabled="data.id" type="date" name="start" id="start" v-model="data.start">
      </div>
      <div class="required field">
        <label for="end">
          <translate translate-context="*/*/*/Noun">End date</translate>
        </label>
        <field-errors :errors="errors.fieldErrors" field="end" />
        <input :disabled="data.id" type="date" name="end" id="end" v-model="data.end">
      </div>
    </div>
    <hr class="hidden">
    <div class="stackable row">
      <div class="required field">
        <label for="worktime_start">
          <translate translate-context="*/*/*/Noun">Worktime start</translate>
        </label>
        <field-errors :errors="errors.fieldErrors" field="worktime_start" />
        <input
          type="time"
          placeholder="09:00"
          name="worktime_start"
          id="worktime_start"
          v-model="data.worktime_start">
      </div>
      <div class="required field">
        <label for="worktime_end">
          <translate translate-context="*/*/*/Noun">Worktime end</translate>
        </label>
        <field-errors :errors="errors.fieldErrors" field="worktime_end" />
        <input
          type="time"
          step="900"
          placeholder="17:00"
          name="worktime_end"
          id="worktime_end"
          v-model="data.worktime_end">
      </div>
    </div>
    <hr class="hidden">
    <template v-if="!data.id">
      <h3>
        <translate translate-context="*/*/*">Checklist</translate>
      </h3>
      <div class="checkbox field" v-for="(label, idx) in checklist" :key="idx">
        <input
        type="checkbox"
        :name="`checklist_${idx}`"
        :id="`checklist_${idx}`"
        required>&nbsp;<label :for="`checklist_${idx}`" v-html="label">
        </label>
      </div>
    </template>
    <app-button :is-loading="isLoading" type="submit">
      <translate translate-context="*/Button/Verb">Save</translate>
    </app-button>
  </form>
</template>
<script>
import ListField from "@/components/ListField"

import http from '@/http'

export default {
  props: {
    obj: {required: false},
    group: {required: true},
  },
  components: {
    ListField
  },
  data () {
    return {
      isLoading: false,
      errors: {},
      showSuccessMessage: false,
      data: {...(this.obj || {
        name: '',
        label: '',
        start: '',
        end: '',
        worktime_start: "09:00",
        worktime_end: "17:00",
        report_email: '',
        data_email: '',
        efx_email: '',
        kpis: [],
      })},
    }
  },
  computed: {
    checklist () {
      return [
        this.$pgettext('*/*/*', 'Alta Call TSM is deployed only on compatible devices (Windows 10 / 64 bits or Windows 10 / 32 bits)'),
        this.$pgettext('*/*/*', "If Alta Call TSM will run on a virtual machine, the corresponding <a href=\"/help/faq#toc-10\" class=\"internal\">VoiceMeeter configuration from our documentation</a> was applied"),
        this.$pgettext('*/*/*', 'A list of performance indicators has been transmitted to Alta Call TSM'),
        this.$pgettext('*/*/*', 'All participants sessions are shown as properly configured on the dashboard'),
      ]
    }
  },
  methods: {
    async save () {
      let response
      this.showSuccessMessage = false
      this.isLoading = true
      this.errors = {}
      let data = {...this.data, group: this.group}
      if (data.id) {
        response = http.patch(`it/abtests/${data.id}`, data)
      } else {
        response = http.post(`it/abtests`, data)
      }
      try {
        response = await response
      } catch (e) {
        this.errors = e.backendErrors
      } finally {
        this.isLoading = false
      }

      if (response.data) {
        this.showSuccessMessage = true
        this.data.id = response.data.id
        this.$emit("save", response.data)
      }
      this.$nextTick(() => {
        this.$el.querySelectorAll('[role="alert"]')[0].scrollIntoView()
      })
    }
  }
}
</script>