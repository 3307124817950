<template>
  <div :class="['results-row user-row', {expanded: expand}]">
    <div class="checkbox">
      <slot name="checkbox"></slot>
    </div>
    <div class="monospace">
      <a href="" class="discrete" @click.prevent="$emit('expanded', !expand)">
        <app-icon v-if="expand" name="magnify-minus-outline"></app-icon>
        <app-icon v-else name="magnify-plus-outline"></app-icon>
        <template v-if="obj.name">
          {{ obj.name.slice(0, 12) }}
        </template>
        <template v-else>
          {{ obj.session_hash.slice(0, 8) }}
        </template>
      </a>
    </div>
    <div class="status">
      <div class="controls">
        <span :title="onlineTitle" :class="[{online: online}, {offline: !online}]">
          <app-icon v-if="online" name="power" />
          <app-icon v-else name="close" />&nbsp;
          <span v-if="online"><translate translate-context="*/*/Status">On</translate></span>
          <span v-else><translate translate-context="*/*/Status">Off</translate></span>
        </span>
        <span :title="lockedTitle">
          <app-icon v-if="config.ui_locked" name="lock" class="discrete" />
          <app-icon v-else name="noop" />
        </span>
        <span :title="consentTitle">
          <app-icon
            v-if="!obj.consent.data_collection"
            name="close-circe-outline"
            class="warning" />
          <app-icon v-else name="noop" />
        </span>
        <span :title="errorLabel">
          <app-icon
            name="alert-circle-outline"
            class="warning"
            v-if="obj.machine.issues.length > 0" 
            @click.prevent="$emit('expanded', !expand)"></app-icon>
          <app-icon v-else name="noop" />
        </span>
        <span :title="audioStatusTitle">
          <app-icon v-if="online && obj.machine.audio_status === 'active'"  class="discrete" name="volume-high" />
          <app-icon class="warning" v-else-if="online && ['stopped', 'failed', 'crashed', 'lost_device'].indexOf(obj.machine.audio_status) > -1" name="volume-off" />
          <app-icon v-else name="noop" />
        </span>
      </div>
    </div>
    <div class="ellipsis" v-if="getAbtest(obj.abtest)" :title="getAbtest(obj.abtest).name">
      <a 
        href=""
        class="discrete"
        @click.prevent="$emit('filter', {name: 'abtest', value: obj.abtest})">
        {{ getAbtest(obj.abtest).name }}
      </a>
    </div>
    <div v-else>
      <a 
        href=""
        class="discrete"
        @click.prevent="$emit('filter', {name: 'abtest', value: false})">
        N/A
      </a>
    </div>
    <div class="ellipsis">
      <a 
        href=""
        class="discrete"
        v-if="transformationSource.transformation"
        :title="transformationName"
        @click.prevent="$emit('filter', {name: 'transformation', value: transformationSource.transformation})">
        {{ transformationName }}      
      </a>
    </div>
    <div>
      <a 
        href=""
        class="discrete label"
        v-for="label in obj.labels"
        :key="label"
        @click.prevent="$emit('filter', {name: 'label', value: label})">
        {{ label }}      
      </a>
    </div>
    <div class="ellipsis">
      <a 
        v-if="obj.stream && obj.stream.connected_app"
        :title="obj.stream.connected_app"
        href=""
        class="discrete"
        @click.prevent="$emit('filter', {name: 'connected_app', value: obj.stream.connected_app})">
        {{ connectedAppLabel }}
      </a>
      <span v-else-if="obj.stream && connectedAppLabel === 'N/A'" :title="connectedAppOldVersionLabel">
        {{ connectedAppLabel }}
      </span>
      <span v-else :title="connectedAppLastSeenLabel">
        N/A
      </span>
    </div>
    <div class="detail stackable row" v-if="expand">
      <div class="heavily padded">
        <h3 class="controls">
          <translate translate-context="*/*/Title">User information</translate>
          <span>
            <app-button @click.prevent="refresh" :is-loading="isRefreshing" class="link my-0">
              <translate translate-context="*/*/*/Verb">Refresh</translate>
            </app-button>
            ·
            <router-link :to="{name: 'dashboard.users.detail', params: {id: obj.session_hash, group: $store.state.selectedGroupId}}">
              <app-icon name="link"></app-icon>
            </router-link>
          </span>
        </h3>
        <table class="no-headers mb-regular">
          <tbody>
            <tr>
              <td><translate translate-context="*/*/*">ID</translate></td>
              <td>
                <router-link :to="{name: 'dashboard.users.detail', params: {id: obj.session_hash, group: $store.state.selectedGroupId}}">
                  {{ obj.session_hash.slice(0, 8) }}
                </router-link>
              </td>
            </tr>
            <tr>
              <td><translate translate-context="*/*/*/Noun">Name</translate></td>
              <td>
                <template v-if="obj.name">
                  {{ obj.name }}
                </template>
                <template v-else>
                  N/A
                </template>
              </td>
            </tr>
            <tr>
              <td><translate translate-context="*/*/*">First seen</translate></td>
              <td>
                <time class="discrete" :datetime="obj.first_update" :title="obj.first_update">
                  {{ ago(obj.first_update) }}
                </time>
              </td>
            </tr>
            <tr>
              <td><translate translate-context="*/*/*">Last seen</translate></td>
              <td>
                <time class="discrete" :datetime="obj.last_update" :title="obj.last_update">
                  {{ ago(obj.last_update) }}
                </time>
              </td>
            </tr>
            <tr>
              <td><translate translate-context="*/*/*">A/B Test</translate></td>
              <td v-if="getAbtest(obj.abtest)">
                {{ getAbtest(obj.abtest).name}}
              </td>
              <td v-else>
                N/A
              </td>
            </tr>
            <!-- DISABLED
            <tr>
              <td><translate translate-context="*/*/*">Tuned audio</translate></td>
              <td>
                <template v-if="!obj.last_params_tuning">
                  <translate translate-context="*/*/*">No</translate>
                </template>
                <time 
                  v-else
                  class="discrete"
                  :datetime="obj.last_params_tuning"
                  :title="obj.last_params_tuning">
                  {{ ago(obj.last_params_tuning) }}
                </time>
              </td>
            </tr>
            -->
            <tr>
              <td><translate translate-context="*/*/*/Noun">Consent: data collection</translate></td>
              <td>
                <template v-if="obj.consent.data_collection === null">
                  <app-icon
                    v-if="!obj.consent.data_collection"
                    name="close-circe-outline"
                    class="warning" />
                  <app-icon v-else name="noop" />
                  &nbsp;
                  <translate translate-context="*/*/*/Noun">Unknown</translate>
                </template>
                <template v-else-if="obj.consent.data_collection === true">
                  <translate translate-context="*/*/*">Yes</translate>
                </template>
                <template v-else-if="obj.consent.data_collection === false">
                  <app-icon
                    v-if="!obj.consent.data_collection"
                    name="close-circe-outline"
                    class="warning" />
                  <app-icon v-else name="noop" />
                  &nbsp;
                  <translate translate-context="*/*/*">No</translate>
                </template>
              </td>
            </tr>
            <tr>
              <td><translate translate-context="*/*/*/Noun">Consent: anonymized data</translate></td>
              <td>
                <template v-if="obj.consent.anonymized_data === null">
                  <translate translate-context="*/*/*/Noun">Unknown</translate>
                </template>
                <template v-else-if="obj.consent.anonymized_data === true">
                  <translate translate-context="*/*/*">Yes</translate>
                </template>
                <template v-else-if="obj.consent.anonymized_data === false">
                  <translate translate-context="*/*/*">No</translate>
                </template>
              </td>
            </tr>
            <!-- DISABLED
            <tr>
              <td><translate translate-context="*/*/*/Noun">Consent: recording</translate></td>
              <td>
                <template v-if="obj.consent.recording === null">
                  <translate translate-context="*/*/*/Noun">Unknown</translate>
                </template>
                <template v-else-if="obj.consent.recording === true">
                  <translate translate-context="*/*/*">Yes</translate>
                </template>
                <template v-else-if="obj.consent.recording === false">
                  <translate translate-context="*/*/*">No</translate>
                </template>
              </td>
            </tr>
            -->
          </tbody>
        </table>
        <div class="controls">
          <app-button @click.prevent="resetConsent" :is-loading="isRefreshing" class="link">
            <translate translate-context="*/*/*/Verb">Reset consent</translate>
          </app-button>
        </div>
        <hr class="hidden">
        <div class="controls mb-regular">
          <h4><translate translate-context="*/*/Title">Machine information</translate></h4>
        </div>
        <device-info
          :hide="['ip.local']"
          :machine="obj.machine"></device-info>
        <h4><translate translate-context="*/*/Title">Audio status</translate></h4>
        <audio-info :stream="obj.stream"></audio-info>
      </div>
      <div class="heavily padded">
        <template v-if="obj.machine.issues.length > 0">
          <h4><translate translate-context="*/*/Title">Machine issues</translate></h4>
          <p>
            <app-icon
              name="alert-circle-outline"
              class="warning"></app-icon>&nbsp;
            <translate
              translate-context="*/*/*"
              :translate-params="{count: obj.machine.issues.length}"
              translate-plural="%{ count } issues were detected on this machine."
              :translate-n="obj.machine.issues.length">
              %{ count } issue was detected on this machine.
            </translate>
          </p>
          <issue-message v-for="(issue, idx) in obj.machine.issues" :issue="issue" :key="idx"></issue-message>
        </template>
        <div>
          <div class="controls mb-regular">
            <h4><translate translate-context="*/*/Title">Device settings</translate></h4>
            <app-button
              @click.prevent="rescan"
              :is-loading="isRescanning"
              :title="rescanTitle"
              class="link my-0">
              <translate translate-context="*/*/*/Verb">Rescan devices</translate>
            </app-button>
          </div>
          <form @submit.prevent="submit" :key="lastUpdate">
            <field-errors :errors="errors.nonFieldErrors" />
            <div class="checkbox field">
              <input type="checkbox" name="lock" id="lock" ref="lock" v-model="config.ui_locked">
              <label for="lock">
                <translate translate-context="*/*/*/Verb">Lock configuration · dashboard control only</translate>
              </label>
            </div>
            <div class="field">
              <label for="capture">
                <strong><translate translate-context="*/*/*">Capture device</translate></strong>
              </label>
              <field-errors v-if="errors.fieldErrors" :errors="errors.fieldErrors.config" field="capture_device" />
              <select name="capture" id="capture" ref="capture" v-model="config.capture_device">
                <option
                  v-for="d in captureDevices"
                  :value="d"
                  :key="d">
                    <template v-if="obj.stream && d === obj.stream.capture_device">* </template>{{ d }}
                  </option>
              </select>
              <p v-if="config.capture_device && obj.machine.capture_devices.indexOf(config.capture_device) === -1">
                <app-icon
                  name="alert-circle-outline"
                  class="warning"></app-icon>&nbsp;
                <translate translate-context="*/*/*">This device is not currently present on the user's machine, even though it has been seen before.</translate>
              </p>
            </div>
            <div class="field">
              <label for="playback">
                <strong><translate translate-context="*/*/*">Playback device</translate></strong>
              </label>
              <field-errors v-if="errors.fieldErrors" :errors="errors.fieldErrors.config" field="playback_device" />
              <select name="playback" id="playback" ref="playback" v-model="config.playback_device">
                <option
                  v-for="d in playbackDevices"
                  :value="d"
                  :key="d">
                    <template v-if="obj.stream && d === obj.stream.playback_device">* </template>{{ d }}
                  </option>
              </select>
              <p v-if="config.playback_device && obj.machine.playback_devices.indexOf(config.playback_device) === -1">
                <app-icon
                  name="alert-circle-outline"
                  class="warning"></app-icon>&nbsp;
                <translate translate-context="*/*/*">This device is not currently present on the user's machine, even though it has been seen before.</translate>
              </p>
            </div>
            <div class="field">
              <label for="rate">
                <strong><translate translate-context="*/*/*">Preferred sampling rate</translate></strong>
              </label>
              <field-errors v-if="errors.fieldErrors" :errors="errors.fieldErrors.config" field="sampling_rate" />
              <select name="rate" id="rate" ref="rate"  v-model="config.sampling_rate">
                <option
                  v-for="v in [16000, 32000, 44100, 48000]"
                  :value="v"
                  :key="v">
                    <template v-if="obj.stream && v === obj.stream.sampling_rate">* </template>
                    {{ v }}
                  </option>
              </select>
              <p>
                <translate :translate-params="{rate: (obj.stream || {}).sampling_rate}" translate-context="*/*/Form.Help">
                  Currently used: %{ rate }Hz
                </translate>
              </p>
            </div>
            <div class="success message" v-if="configApplied">
              <p>
                <translate translate-context="*/*/Form.Message">
                  Configuration saved. It may take up to 30s for the new configuration to be picked-up by Alta Call TSM.
                </translate>
              </p>
            </div>
            <div class="controls">
              <app-button @click.prevent="submit" :is-loading="isLoading" type="submit">
                <translate translate-context="*/*/Form.Button">
                  Apply/Reset
                </translate>
              </app-button>
            </div>
          </form>
        </div>
        <hr class="hidden">
        <div class="mt-regular">
          <div class="controls mb-regular">
            <h4><translate translate-context="*/*/Title">Transformation settings</translate></h4>
          </div>
          <hr class="hidden">
          <form @submit.prevent="submitTransfoParams" :key="lastUpdate">
            <field-errors :errors="errors.nonFieldErrors" />
            <div :class="['field', param.type]" v-for="param in transfoParamsConfig" :key="param.name">
              <template v-if="param.type === 'checkbox'">
                <input
                  type="checkbox"
                  :id="`tp-${param.name}`"
                  :name="`tp-${param.name}`"
                  v-model="transfoParams[param.name]"
                  v-bind="param.inputAttrs"
                >
                <label :for="`tp-${param.name}`">
                  {{ param.label }}
                </label>
                <field-errors
                  v-if="errors.fieldErrors"
                  :errors="errors.fieldErrors.transformation_params" :field="param.name" />
              </template>
              <template v-else>
                <label :for="`tp-${param.name}`">
                  <strong>{{ param.label }}</strong>
                </label>
                <field-errors
                  v-if="errors.fieldErrors"
                  :errors="errors.fieldErrors.transformation_params" :field="param.name" />
                
                <div class="stackable row">
                  <input
                    type="range"
                    :id="`tp-${param.name}-range`"
                    :name="`tp-${param.name}-range`"
                    v-model.number="transfoParams[param.name]"
                    v-bind="param.inputAttrs"
                  >
                  <input
                    type="number"
                    :id="`tp-${param.name}`"
                    :name="`tp-${param.name}`"
                    v-model.number="transfoParams[param.name]"
                    v-bind="param.inputAttrs"
                  >
                </div>
    
              </template>
              <p v-if="param.help">
                {{ param.help }}
              </p>
              <a href="" @click.prevent="transfoParams[param.name] = param.default">
                <translate translate-context="*/*/Form.Button">
                  Reset
                </translate>
              </a>
              <hr>
            </div>
            <div class="success message" v-if="transfoParamsApplied">
              <p>
                <translate translate-context="*/*/Form.Message">
                  Configuration saved. It may take up to 30s for the new configuration to be picked-up by Alta Call TSM.
                </translate>
              </p>
            </div>
            <div class="controls">
              <app-button
                class="link"
                @click.prevent="resetTransfoParams(); submitTransfoParams()"
                :is-loading="isLoading"
              >
                <translate translate-context="*/*/Form.Button">
                  Reset Values
                </translate>
              </app-button>
              <app-button @click.prevent="submitTransfoParams" :is-loading="isLoading" type="submit">
                <translate translate-context="*/*/Form.Button">
                  Apply
                </translate>
              </app-button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import semverLt from 'semver/functions/lt'
import uniq from 'lodash/uniq'
import isAfter from 'date-fns/isAfter'
import subSeconds from 'date-fns/subSeconds'

import http from  '@/http'

import DeviceInfo from './DeviceInfo'
import AudioInfo from './AudioInfo'
import IssueMessage from './IssueMessage'

import {ago} from '@/time'

export default {
  props: {
    obj: {},
    meta: {},
    expand: {type: Boolean, default: false},
  },
  components: {
    AudioInfo,
    DeviceInfo,
    IssueMessage
  },
  data () {
    return {
      isRefreshing: false,
      isRescanning: false,
      isLoading: false,
      errors: {},
      lastUpdate: new Date(),
      config: null,
      transfoParams: {
        ps: null,
        fw: null,
        pe: null,
        efx_smile_alpha: null,
        efx_inteligibility_scaling: null,
        efx_denoiser_gate_coef_autobias: null,
      },
      configApplied: null,
      transfoParamsApplied: null,
      showDeleteModal: false,
      isDeleting: false,
      ago,
    }
  },
  created () {
    this.transfoParamsConfig.forEach(p => {
      if (this.obj.transformation_params[p.name] != undefined) {
        this.transfoParams[p.name] = this.obj.transformation_params[p.name]
      } else {
        this.transfoParams[p.name] = p.default
      }
    })
  },
  computed: {
    online () {
      let d = new Date(this.obj.last_update)
      let b = subSeconds(new Date(), 300)
      return isAfter(d, b) && this.obj.machine && !this.obj.machine.session_locked
    },
    onlineTitle () {
      let msg = this.$pgettext("*/*/Tooltip", "Last seen on %{ date }")
      return this.$gettextInterpolate(msg, {date: this.obj.last_update})
    },
    transformationSource () {
      return this.obj.stream || this.config
    },
    transformationName () {
      if (this.meta.transformations.length === 0) {
        return 0
      }
      let t = this.meta.transformations.filter((t) => {
        return t.id === this.transformationSource.transformation
      })
      if (t.length > 0) {
        if (t[0].name === "Manual") {
          return `${t[0].name} (${this.transformationSource.transformation_mode})`
        }
        return t[0].name
      }
      return null
    },
    captureDevices () {
      let devices = [...this.obj.machine.capture_devices]
      if (this.config.capture_device) {
        devices.push(this.config.capture_device)
      }
      return uniq(devices)
    },
    playbackDevices () {
      let devices = [...this.obj.machine.playback_devices]
      if (this.config.playback_device) {
        devices.push(this.config.playback_device)
      }
      return uniq(devices)
    },
    audioStatusTitle () {
      const titles = {
        'active': this.$pgettext('*/*/*', 'Audio is transforming'),
        'stopped': this.$pgettext('*/*/*', 'Audio is not transforming (needs configuration)'),
        'failed': this.$pgettext('*/*/*', 'Impossible to transform with current configuration'),
        'crashed': this.$pgettext('*/*/*', 'Audio processing has crashed'),
        'lost_device': this.$pgettext('*/*/*', "Audio device isn't available"),
      }
      return titles[this.obj.machine.audio_status]
    },
    lockedTitle () {
      return this.$pgettext('*/*/*', 'This user setup is locked and can only be modified through this dashboard.')
    },
    consentTitle () {
      return this.$pgettext('*/*/*', "This user hasn't consented to the use of Alta Call TSM.")
    },
    rescanTitle () {
      return this.$pgettext('*/*/*', 'Request Alta Call TSM to rescan available audio devices. It may take up to a couple of minutes, and you\'ll need to refresh the current user to see the updated list.')
    },
    errorLabel () {
      return this.$pgettext("*/*/Tooltip", "This machine has some configuration issues, expand the row for more information")
    },

    connectedAppOldVersionLabel () {
      return this.$pgettext("*/*/Tooltip", "This machine is running an old version of Alta Call TSM, please upgrade to a newer version to use this feature.")
    },
    connectedAppLastSeenLabel () {
      let msg
      if (this.obj.stream && this.obj.stream.connected_app_last_update) {
        msg = this.$pgettext("*/*/Tooltip", "This machine was connected to an application %{ ago }.")
        return this.$gettextInterpolate(msg, {ago: ago(this.obj.stream.connected_app_last_update)})
      } else {
        return this.$pgettext("*/*/Tooltip", "This machine was never connected to an application.")
      }
    },
    connectedAppLabel() {
      let name = this.obj.stream.connected_app
      if (semverLt(this.obj.machine.version, '0.24.4')) {
        return 'N/A'
      }
      if (!name) {
        return null
      }
      if (!this.meta || !this.meta.connected_apps) {
        return name
      }
      return this.meta.connected_apps.filter(
        (a) => { return a.name === name}
      ).map((a) => { return a.label })[0] || name
    },
    transfoParamsConfig () {
      return [
        {
          name: "efx_smile_alpha",
          label: this.$pgettext("*/*/*", "Smile effect"),
          help: this.$pgettext("*/*/*", "Alpha parameter of the smile effect. The bigger the value, the more smily should be the effect. 1 is the original, <1 will unsmile."),
          default: 1,
          type: "range",
          inputAttrs: {
            min: 0.8,
            max: 2,
            step: 0.01,
          }
        },
        {
          name: "efx_inteligibility_scaling",
          label: this.$pgettext("*/*/*", "Intellegibility effect"),
          help: this.$pgettext("*/*/*", "Size effect of the Intelligibility effect, 0.0 means no effect, 1.0 is maximum effect."),
          default: 0,
          type: "range",
          inputAttrs: {
            min: 0,
            max: 1,
            step: 0.01,
          }
        },
        {
          name: "efx_denoiser_gate_coef_autobias",
          label: this.$pgettext("*/*/*", "Denoiser effect"),
          help: this.$pgettext("*/*/*", "Bias, in dB for the automatic audio level of the Denoiser effect. The higher the value, the more denoised the sound."),
          default: -128,
          type: "range",
          inputAttrs: {
            min: -128,
            max: 0,
            step: 1,
          }
        },
        {
          name: "ps",
          label: this.$pgettext("*/*/*", "Pitch scaling"),
          help: this.$pgettext("*/*/*", "Pitch scaling coefficient"),
          default: 1,
          type: "range",
          inputAttrs: {
            min: 0,
            max: 3,
            step: 0.1,
          }
        },
        {
          name: "fw",
          label: this.$pgettext("*/*/*", "Frequency warping"),
          help: this.$pgettext("*/*/*", "Frequency warping coefficient. This warps the amplitude spectral envelope of the spectrum (push the smooth shapes higher (with fw>1.0), or lower (with fw<1.0))."),
          type: "range",
          default: 1,
          inputAttrs: {
            min: 0,
            max: 3,
            step: 0.1,
          }
        },
      ]
    }
  },
  methods: {
    async refresh () {
      this.isRefreshing = true
      try {
        let response = await http.get(`it/users/${this.obj.session_hash}`)
        this.$emit("updated", response.data)
      } finally {
        this.isRefreshing = false
      }
    },
    async rescan () {
      this.isRescanning = true
      let payload = {
        action: 'refresh_devices',
        objects: [this.obj.machine.name],
      }
      try {
        await http.post(`it/machines/action`, payload)
      } finally {
        this.isRescanning = false
      }
    },
    async resetConsent () {
      this.isRefreshing = true
      try {
        let response = await http.post(`it/users/${this.obj.session_hash}/reset-consent`)
        this.$emit("updated", response.data)
      } finally {
        this.isRefreshing = false
      }
    },
    async submit () {
      this.isLoading = true
      this.configApplied = null
      this.errors = {}
      let payload = {
        config: {
          capture_device: this.$refs.capture.value,
          playback_device: this.$refs.playback.value,
          ui_locked: this.$refs.lock.checked,
          sampling_rate: parseInt(this.$refs.rate.value),
        }
      }
      try {
        let response = await http.put(`it/users/${this.obj.session_hash}`, payload)
        this.$emit("updated", response.data)
        this.configApplied = true
      } catch (e) {
        this.errors = e.backendErrors
      } finally {
        this.isLoading = false
      }
    },
    async submitTransfoParams () {
      this.isLoading = true
      this.transfoParamsApplied = null
      this.errors = {}
      let payload = {
        transformation_params: {
          ...this.transfoParams
        }
      }
      try {
        let response = await http.put(`it/users/${this.obj.session_hash}`, payload)
        this.$emit("updated", response.data)
        this.transfoParamsApplied = true
      } catch (e) {
        this.errors = e.backendErrors
      } finally {
        this.isLoading = false
      }
    },
    getConnectedAppLabel(meta, name) {
      if (!meta || !meta.connected_apps) {
        return name
      }
      return meta.connected_apps.filter(
        (a) => { return a.name === name}
      ).map((a) => { return a.label })[0] || name
    },
    getAbtest (id) {
      return this.meta.abtests.filter((a) => {
        return a.id == id
      })[0]
    },
    getAbtestTitle (id) {
      let test = this.getAbtest(id)
      if (!test) {
        return
      }
      let msg = this.$pgettext("*/*/Tooltip", "Part of A/B Test %{ test }")
      return this.$gettextInterpolate(msg, {test: test.name})
    },
    resetTransfoParams () {
      this.transfoParamsConfig.forEach(p => {
        this.transfoParams[p.name] = p.default
      })
    }
  },
  watch: {
    obj: {
      handler (v) {
        this.lastUpdate = new Date()
        this.config = {...v.config}
      },
      immediate: true,
    }
  }
}
</script>