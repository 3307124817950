
<template>
  <div class="main">
    <section class="bordered padded" v-if="!id">
      <form @submit.prevent>
        <div class="stackable row">
          <div class="field">
            <label for="search">
              <translate translate-context="*/*/*/Verb">Search</translate>
            </label>
            <input id="search" v-model="filters.q" :placeholder="searchPlaceholder" :title="searchPlaceholder">
          </div>
        </div>
      </form>
    </section>
    <section class="v-spaced bordered padded" v-if="isLoading">
      <loading-area :is-loading="isLoading">
        <span><translate translate-context="*/*/Loader">Loading data…</translate></span>
      </loading-area>
    </section>
    <section class="v-spaced bordered padded" v-else-if="results && results.count === 0">
      <empty-state>
        <template v-slot:controls >
          · <a href="" v-if="hasFilters" @click.prevent="filters = {...initialFilters}">
            <translate translate-context="*/*/Form.Link/Action">Reset filters</translate>
          </a>
        </template>
      </empty-state>
    </section>
    <section class="v-spaced bordered padded" v-else-if="results">
      <header class="controls mb-regular">
        <h2>
          <translate
            :translate-params="{count: results.count}"
            translate-plural="%{ count } machines running an outdated version of Alta Call"
            :translate-n="results.count"
            translate-context="*/*/Title">
            %{ count } machine running an outdated version of Alta Call
          </translate>
        </h2>
        <a href="" v-if="hasFilters" @click.prevent="filters = {...initialFilters}">
          <translate translate-context="*/*/Form.Link/Action">Reset filters</translate>
        </a>
      </header>
      <table>
        <thead>
          <th>
            <sort-link @updated="filters.o = $event" :current-sort="filters.o" value="last_update">
              <translate translate-context="*/*/*">Last seen</translate>
            </sort-link>
          </th>
          <th>
            <translate translate-context="*/*/*">Version</translate>
          </th>
          <th>
            <translate translate-context="*/*/*">Local IP</translate>
          </th>
          
          <th>
            <translate translate-context="*/*/*">Machine</translate>
          </th>
          <th>
            <sort-link @updated="filters.o = $event" :current-sort="filters.o" value="hostname">
              <translate translate-context="*/*/*">Hostname</translate>
            </sort-link>
          </th>
          <th>
            <translate translate-context="*/*/*">Last user</translate>
          </th>
        </thead>
        <tbody>
          <tr v-for="obj in results.results" :key="`${obj.last_update}`">
            <td>
              {{ ago(obj.last_update) }}
            </td>
            <td class="monospace">
              {{ obj.version }}
            </td>
            <td class="monospace">
              {{ obj.local_ip_address }}
            </td>
            <td class="monospace">
              {{ obj.name.slice(0, 8) }}
            </td>
            <td class="monospace">
              {{ obj.hostname }}
            </td>
            <td>
              {{ obj.last_user_name }}
            </td>
          </tr>
        </tbody>
      </table>
    </section>
    <pagination
      v-if="results && results.count > pageSize"
      @page-changed="filters.page = $event"
      :current="filters.page"
      :paginate-by="pageSize"
      :total="results.count"
      ></pagination>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import isEqual from 'lodash/isEqual'
import forOwn from 'lodash/forOwn'
import http from '@/http'

import Pagination from '@/components/Pagination'
import SortLink from '@/components/SortLink'
import { ago } from '@/time'

import {getIssueHelp} from '@/copy'

function toQueryString (obj) {
  let d = {}
  forOwn(obj, (val, key) => {
    if (val) {
      d[key] = val
    }
  })
  return d
}

const initialFilters = {
  o: '-last_update',
  page: 1,
  q: '',
}

export default {
  props: {
    group: {required: true},
  },
  components: {
    Pagination,
    SortLink,
  },
  data () {
    let f = {
      errors: {},
      isLoading: false,
      results: null,
      filters: {...initialFilters, ...this.$route.query},
      initialFilters: initialFilters,
      pageSize: 100,
      ago,
    }
    return f
  },
  async created () {    
    let fetch = this.fetchData(this.filters)
    await fetch
  },
  computed: {
    hasFilters () {
      return !isEqual(initialFilters, this.filters)
    },
    allFilters () {
      return {...this.filters}
    },
    searchPlaceholder () {
      return this.$pgettext('*/*/Search.Placeholder', 'IP, Agent name…')
    },
  },
  methods: {
    getIssueHelp (issue) {
      return getIssueHelp(this, issue)
    },
    async fetchData(filters) {
      let response
      this.isLoading = true
      this.errors = {}
      this.results = null
      let params = {...filters, page_size: this.pageSize, group: this.group}
      try {
        response = await http.get('it/outdated-machines', {params})
        this.results = response.data
      } catch (e) {
        this.errors = e.backendErrors
      } finally {
        this.isLoading = false
      }
    },
    async refresh () {
      this.expandedRow = null
      if (!this.id) {
        this.$router.push({query: toQueryString(this.filters)})
      }
      window.scrollTo(0, 0);
      await this.fetchData(this.filters)
    }
  },
  watch: {
    allFilters: {
      deep: true,
      handler: debounce(
        async function (n, o) {
          if (n.page === o.page) {
            this.filters.page = 1
          }
          await this.refresh()
        }, 250
      )
    },
  }
}
</script>
