
<template>
  <div class="tiny narrow main">
    <h2>
      <app-icon name="chart-box" />&nbsp;
      <translate translate-context="*/*/*">A/B Tests</translate>
    </h2>
    <p class="controls">
      <router-link :to="{name: 'dashboard.requests', params: {group: $store.state.selectedGroupId}}">
        <translate translate-context="*/*/*">
          Request a new A/B test
        </translate>
      </router-link>
      <span>
        <a href="#" :class="[{active: filters.ongoing}]" @click.prevent="filters.ongoing = true">
          <translate translate-context="*/*/*">
            Ongoing tests
          </translate>
        </a> · 
        <a href="#" :class="[{active: !filters.ongoing}]" @click.prevent="filters.ongoing = null">
          <translate translate-context="*/*/*">
            All tests
          </translate>
        </a>
      </span>
    </p>
    <section v-if="isLoading" class="v-spaced bordered padded">
      <loading-area :is-loading="isLoading">
        <span><translate translate-context="*/*/Loader">Loading A/B tests…</translate></span>
      </loading-area>
    </section>
    <section v-else-if="results && results.count === 0" class="v-spaced bordered padded">
      <empty-state ></empty-state>
    </section>
    <template v-else-if="results">
      <abtest-card
        class="card mb-regular"
        @refresh="fetchData(filters)"
        :test="test"
        :key="test.id" v-for="test in results.results"></abtest-card>
      <pagination
        v-if="results && results.count > pageSize"
        @page-changed="filters.page = $event"
        :current="filters.page"
        :paginate-by="pageSize"
        :total="results.count"
        ></pagination>
    </template>
  </div>
</template>

<script>
import forOwn from 'lodash/forOwn'
import debounce from 'lodash/debounce'
import http from '@/http'

import Pagination from '@/components/Pagination'
import AbtestCard from '@/components/AbtestCard'

const initialFilters = {
  o: '-start',
  ongoing: true,
}


function toQueryString (obj) {
  let d = {}
  forOwn(obj, (val, key) => {
    if (val) {
      d[key] = val
    }
  })
  return d
}
export default {
  props: {
    id: {required: false},
    group: {},
  },
  components: {
    Pagination,
    AbtestCard,
  },
  data () {
    let f = {
      errors: {},
      isLoading: false,
      results: null,
      filters: {...initialFilters, ...this.$route.query},
      initialFilters: initialFilters,
      pageSize: 30,
    }
    return f
  },
  async created () {    
    await this.fetchData(this.filters)
  },
  computed: {
    allFilters () {
      return {...this.filters}
    },
  },
  methods: {
    async fetchData(filters) {
      let response
      this.isLoading = true
      this.errors = {}
      this.results = null
      let params = {...filters, page_size: this.pageSize, group: this.group}
      try {
        response = await http.get('it/abtests', {params})
        this.results = response.data
      } catch (e) {
        this.errors = e.backendErrors
      } finally {
        this.isLoading = false
      }
    },
    async refresh () {
      this.$router.push({query: toQueryString(this.filters)})
      window.scrollTo(0, 0);
      await this.fetchData(this.filters)
    }
  },
  watch: {
    allFilters: {
      deep: true,
      handler: debounce(
        async function (n, o) {
          if (n.page === o.page) {
            this.filters.page = 1
          }
          await this.refresh()
        }, 250
      )
    }
  }
}
</script>
