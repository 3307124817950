
<template>
  <div class="narrow tiny main">
    <h2>
      <app-icon name="chart-box" />&nbsp;
      <translate translate-context="*/*/*">Labels</translate>
    </h2>
    <section class="v-spaced bordered padded">
      <p><translate translate-context="*/*/*">Use labels to easily categorize and find users. Each user can have multiple labels.</translate></p>
      <form action="" @submit.prevent="create">
        <h3>
          <translate translate-context="*/*/*">Create a new label</translate>
        </h3>
        <div class="field">
          <label for="name"><translate translate-context="*/*/*">Label name</translate></label>
          <field-errors :errors="errors.fieldErrors" field="name" />
          <input
            type="text"
            name="name"
            id="name"
            required
            placeholder="client:company"
            v-model="labelName">
        </div>
        <app-button :is-loading="isCreating" :disabled="!labelName" type="submit">
          <translate translate-context="*/Button/Verb">Save</translate>
        </app-button>
      </form>
    </section>
    <section v-if="isLoading" class="v-spaced bordered padded">
      <loading-area :is-loading="isLoading">
        <span><translate translate-context="*/*/Loader">Loading labels…</translate></span>
      </loading-area>
    </section>
    <section v-else-if="results && results.count === 0" class="v-spaced bordered padded">
      <empty-state ></empty-state>
    </section>
    <section v-else-if="results" class="v-spaced bordered padded">
      <h3><translate translate-context="*/*/*">Existing labels</translate></h3>
      <table>
        <thead>
          <th><translate translate-context="*/*/*">Name</translate></th>
          <th><translate translate-context="*/*/*">Users</translate></th>
          <th></th>
        </thead>
        <tbody>
          <tr v-for="obj in results.results" :key="obj.id">
            <td>
              <form action="" v-if="toUpdate === obj" @submit.prevent="update">
                <div class="field">
                  <input type="text" v-model="obj.name" required>
                </div>
                <app-button :is-loading="isUpdating" @click.prevent="update">
                  <translate translate-context="*/Button/Verb">Save</translate>
                </app-button>
              </form>
              <template v-else>{{ obj.name }}</template>
            </td>
            <td>
              <router-link :to="{name: 'dashboard.users', query: {label: obj.name}, params: {group: $store.state.selectedGroupId}}">
                {{ obj.users }}
              </router-link>
            </td>
            <td>
              <a href="" @click.prevent="toUpdate = obj">
                <app-icon name="pencil"></app-icon>
                <translate translate-context="*/*/*/Verb">Edit</translate>
              </a>
              &nbsp;
              <a href="" @click.prevent="toAssign = obj; showAssignModal = true">
                <app-icon name="upload"></app-icon>
                <translate translate-context="*/Button/Verb">Assign…</translate>
              </a>
              &nbsp;
              <a href="" @click.prevent="toDelete = obj; showDeleteModal = true">
                <app-icon name="trash-can"></app-icon>
                <translate translate-context="*/Button/Verb">Delete…</translate>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <app-modal v-if="showDeleteModal" @close="showDeleteModal = false" :submit-action="del">
        <template v-slot:header>
          <h3>
            <translate translate-context="*/*/*" :translate-params="{name: toDelete.name}">Delete label %{ name }</translate>
          </h3>
        </template>
        <p>
          <translate translate-context="*/*/*">By deleting this label, it will removed immediately and completely from our database.</translate>
        </p>
        <p>
          <strong>
            <translate translate-context="*/*/*">This action is irreversible, please proceed with caution.</translate>
          </strong>
        </p>
        <template v-slot:action-button>
          <app-button :is-loading="isDeleting" @click.prevent="del">
            <translate translate-context="*/Button/Verb">Delete</translate>
          </app-button>
        </template>
      </app-modal>
      <app-modal v-if="showAssignModal" @close="showAssignModal = false" :submit-action="assign">
        <template v-slot:header>
          <h3>
            <translate translate-context="*/*/*" :translate-params="{name: toAssign.name}">Assign users to label %{ name }</translate>
          </h3>
        </template>
        <p>
          <translate translate-context="*/*/*">Use the form below to assign a batch of users to this label.</translate>
        </p>
        
        <form @click.prevent="" id="submit-batch" required>
          <div class="field">
            <label for="batch-users">
              <translate translate-context="*/*/*">Users</translate>
            </label>
            <textarea name="batch-users" id="batch-users" rows="15" v-model="users"></textarea>
            <p>
              <translate translate-context="Help/*/Paragraph">
                You can copy paste a list of users hash or session name separated by coma, semicolon, spaces or line breaks.
              </translate>
            </p>
          </div>
          <field-errors :errors="errors.nonFieldErrors" />
        </form>
        <template v-slot:action-button>
          <app-button
            :is-loading="isAssigning"
            @click.prevent="assign"
            form="submit-batch"
            :disabled="stringToArray(users).length === 0">
            <translate
              translate-context="*/*/*"
              :translate-params="{count: stringToArray(users).length}"
              translate-plural="Assign %{ count } users"
              :translate-n="stringToArray(users).length">
              Assign %{ count } user
            </translate>
          </app-button>
        </template>
      </app-modal>
    </section>
  </div>
</template>

<script>
import http from '@/http'

import {stringToArray} from '@/parsing'

export default {
  props: {
    group: {required: true}
  },
  data () {
    let f = {
      errors: {},
      isLoading: false,
      isCreating: false,
      results: null,
      labelName: "",
      toDelete: null,
      toUpdate: null,
      showDeleteModal: false,
      isDeleting: false,
      isUpdating: false,
      users: "",
      toAssign: null,
      showAssignModal: false,
      isAssigning: false,
      stringToArray
    }
    return f
  },
  async created () {    
    await this.fetchData()
  },
  methods: {
    async fetchData() {
      let response
      this.isLoading = true
      this.errors = {}
      this.results = null
      try {
        response = await http.get('it/labels', {params: {o: "name", page_size: 1000, group: this.group}})
        this.results = response.data
      } catch (e) {
        this.errors = e.backendErrors
      } finally {
        this.isLoading = false
      }
    },
    async create() {
      this.isCreating = true
      this.errors = {}
      try {
        await http.post('it/labels', {name: this.labelName, group: this.group})
        await this.refresh()
      } catch (e) {
        this.errors = e.backendErrors
      } finally {
        this.isCreating = false
      }
    },
    async del () {
      if (!this.toDelete) {
        return
      }
      this.isDeleting = true
      try {
        await http.delete(`it/labels/${this.toDelete.id}`)
        await this.refresh()
      } finally {
        this.isDeleting = false
        this.toDelete = null
        this.showDeleteModal = false
      }
    },
    async assign () {
      if (!this.toAssign) {
        return
      }
      this.errors = {}
      this.isAssigning = true
      let payload = {
        objects: this.stringToArray(this.users),
        action: "add_labels",
        data: [this.toAssign.name],
        filters: {},
      }
      let response
      try {
        response = await http.post('it/users/action', payload)
        await this.refresh()
        this.users = ""
      } catch (e) {
        this.errors = e.backendErrors
      } finally {
        this.isAssigning = false
        if (response && !response.backendErrors) {
          this.toAssign = null
          this.showAssignModal = false
        }
      }
    },
    async update () {
      if (!this.toUpdate) {
        return
      }
      this.isUpdating = true
      try {
        await http.patch(`it/labels/${this.toUpdate.id}`, {name: this.toUpdate.name})
      } finally {
        this.isUpdating = false
        this.toUpdate = null
      }
    },
    async refresh () {
      await this.fetchData()
    }
  }
}
</script>
