<template>
  <span>
    <a href="" @click.prevent="download">
      <app-icon v-if="isLoading" name="refresh" class="rotating mr-small"></app-icon>
      <app-icon v-else name="download" class="discrete mr-small"></app-icon>
      <slot></slot>
    </a>
    <a href="" class="hidden" ref="trigger"></a>
  </span>
</template>
<script>
// at some point, we need to download some files, while authenticating
// with a header. Web browsers don't support that, so we have
// to hack a bit. Basically, we:
//   1. download the file via Ajax (hence, keeping proper authentication)
//   2. create a local file in javascript with the Ajax response content
//   3. trigger a download of this local file via a handcrafted <a>
// cf https://stackoverflow.com/a/24523253

import http from '@/http'

export default {
  props: ['apiUrl', 'filename'],
  data () {
    return {
      isLoading: false,
      errors: {}
    }
  },
  methods: {
    async download () {
      this.isLoading = true
      this.errors = {}
      try {
        let response = await http.get(this.apiUrl)
        this.triggerBrowserDownload(response)
      } catch (e) {
        console.log('ERROR', e)
        this.errors = e.backendErrors
      } finally {
        this.isLoading = false
      }
    },
    triggerBrowserDownload (response) {
      let anchor = this.$refs.trigger
      let windowUrl = window.URL || window.webkitURL
      let blob = new Blob([response.data], { type: response.headers['content-type'] })
      let url = windowUrl.createObjectURL(blob)
      anchor.setAttribute('href', url)
      anchor.setAttribute('download', this.filename)
      anchor.click()
      windowUrl.revokeObjectURL(url)
    }
  }
}
</script>