
<template>
  <div class="main">
    <section class="v-spaced bordered padded">
      <h2>
        <translate v-if="id" key="1" translate-context="*/*/*">Edit A/B Test</translate>
        <translate v-else key="2" translate-context="*/*/*">Request A/B Test</translate>
      </h2>
      <loading-area v-if="isLoading" :is-loading="isLoading">
        <span><translate translate-context="*/*/Loader">Loading…</translate></span>
      </loading-area>
      <abtest-form v-if="!isLoading" :group="group" :obj="abtest" @save="save"></abtest-form>
    </section>
  </div>
</template>

<script>
import AbtestForm from '@/components/AbtestForm'

import http from '@/http'

export default {
  props: {
    id: {required: false},
    group: {required: false},
  },
  components: {
    AbtestForm
  },
  data () {
    return {
      abtest: null,
      isLoading: false,
    }
  },
  async created () {
    await this.fetchData()
  },
  methods: {
    async fetchData () {
      if (!this.id) {
        return
      }
      this.isLoading = true
      let response = await http.get(`it/abtests/${this.id}`)
      this.abtest = response.data
      this.isLoading = false
    },
    save (obj) {
      this.abtest = obj;
      if (this.id) {
        return
      }
      this.$router.push({name: 'dashboard.abtests.edit', params: {id: obj.id}})
    }
  }
}
</script>
