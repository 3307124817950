
<template>
  <div class="main">
    <section class="bordered padded" v-if="!id">
      <form @submit.prevent>
        <div class="stackable row">
          <div class="field">
            <label for="search">
              <translate translate-context="*/*/*/Verb">Search</translate>
            </label>
            <input id="search" v-model="filters.q" :placeholder="searchPlaceholder" :title="searchPlaceholder">
          </div>
          <div class="field">
            <label for="status">
              <translate translate-context="*/*/*/Noun">Status</translate>
            </label>
            <select id="status" v-model="filters.online">
              <option value="">
                <translate translate-context="*/Field/Value">Any</translate>
              </option>
              <option value="true"><translate translate-context="*/*/Status">Online</translate></option>
              <option value="false"><translate translate-context="*/*/Status">Offline</translate></option>
            </select>
          </div>
          <div class="field">
            <label for="country"><translate translate-context="*/*/*">Country</translate></label>
            <select id="country" v-model="filters.country">
              <option value=""><translate translate-context="*/*/*">All countries</translate></option>
              <option
                :value="country.code"
                v-for="country in meta.countries"
                :key="country.code">{{ country.name }}</option>
            </select>
          </div>
          <div class="field">
            <label for="transformation"><translate translate-context="*/*/*/Voice transformation">Transformation</translate></label>
            <select id="transformation" v-model="filters.transformation">
              <option value=""><translate translate-context="*/Field/Value">Any</translate></option>
              <option
                :value="transformation.id"
                v-for="transformation in meta.transformations"
                :key="transformation.id">{{ transformation.name }}</option>
            </select>
          </div>
          <div class="field">
            <label for="error"><translate translate-context="*/*/*">Issues</translate></label>
            <select id="error" v-model="filters.error">
              <option value=""><translate translate-context="*/Field/Value">All users</translate></option>
              <option value="true"><translate translate-context="*/*/*">Any issue</translate></option>
              <option value="false"><translate translate-context="*/*/*">No issue</translate></option>
              <option :value="issue" v-for="issue in meta.issues" :key="issue">{{ issue }}</option>
            </select>
          </div>
        </div>
        <hr class="hidden" />
        <div class="stackable row">
          <div class="field">
            <label for="label"><translate translate-context="*/*/*/Noun">Label</translate></label>
            <select id="label" v-model="filters.label">
              <option value=""><translate translate-context="*/Field/Value">Any</translate></option>
              <option :value="label" v-for="label in meta.labels" :key="label">{{ label }}</option>
            </select>
          </div>
          <div class="field">
            <label for="abtest"><translate translate-context="*/*/*">A/B Test</translate></label>
            <select id="abtest" v-model="filters.abtest">
              <option value=""><translate translate-context="*/Field/Value">All users</translate></option>
              <option value="true"><translate translate-context="*/*/*">Any test</translate></option>
              <option value="false"><translate translate-context="*/*/*">No test</translate></option>
              <option :value="abtest.id" v-for="abtest in meta.abtests" :key="abtest.id">{{ abtest.name }}</option>
            </select>
          </div>
          <!-- DISABLED
          <div class="field">
            <label for="needs-tuning"><translate translate-context="*/*/*">Tuned audio</translate></label>
            <select id="needs-tuning" v-model="filters.audio_tuned">
              <option value="">
                <translate translate-context="*/Field/Value">Any</translate>
              </option>
              <option value="true"><translate translate-context="*/*/*">Yes</translate></option>
              <option value="false"><translate translate-context="*/*/*">No</translate></option>
            </select>
          </div>
          -->
          <!-- DISABLED
          <div class="field">
            <label for="consent-recording"><translate translate-context="*/*/*">Recording Consent</translate></label>
            <select id="consent-recording" v-model="filters.consent_recording">
              <option value="">
                <translate translate-context="*/Field/Value">Any</translate>
              </option>
              <option value="true"><translate translate-context="*/*/*">Yes</translate></option>
              <option value="false"><translate translate-context="*/*/*">No</translate></option>
            </select>
          </div>
          -->
          <div></div>
        </div>
      </form>
    </section>
    <section class="v-spaced bordered padded" v-if="isLoading">
      <loading-area :is-loading="isLoading">
        <span><translate translate-context="*/*/Loader">Loading users…</translate></span>
      </loading-area>
    </section>
    <section class="v-spaced bordered padded" v-else-if="results && results.count === 0">
      <empty-state>
        <template v-slot:controls >
          · <a href="" v-if="hasFilters" @click.prevent="filters = {...initialFilters}">
            <translate translate-context="*/*/Form.Link/Action">Reset filters</translate>
          </a>
        </template>
      </empty-state>
    </section>
    <section class="v-spaced bordered padded" v-else-if="results">
      <header class="controls mb-regular">
        <h2>
          <translate
            :translate-params="{count: results.count}"
            translate-plural="%{ count } matching users"
            :translate-n="results.count"
            translate-context="*/*/Title">
            %{ count } matching user
          </translate>
        </h2>
        <router-link :to="{name: 'dashboard.users', params: {group: $store.state.selectedGroupId}}" v-if="id" >
          <translate translate-context="*/*/*/Action">Back to users list</translate>
        </router-link>
        <a href="" v-else-if="hasFilters" @click.prevent="filters = {...initialFilters}">
          <translate translate-context="*/*/Form.Link/Action">Reset filters</translate>
        </a>
      </header>
      <form @submit.prevent="batchAction(currentAction, checked)" v-if="checked.length > 0" class="segment">
        <div class="field">
          <div class="controls mb-regular">
            <label for="batch-action">
              <strong>
                <translate translate-context="*/*/Form.Label">Action</translate>
              </strong>
            </label>
            <span>
              <translate
                :translate-params="{count: checkedCount, total: results.count}"
                translate-plural="%{ count } on %{ total } row selected"
                :translate-n="results.count"
                translate-context="*/*/Form.Help">
                %{ count } on %{ total } rows selected
              </translate>
              
              <template v-if="checked.length === pageSize">
                · 
                <a v-if="selectAllPages" href="" @click.prevent="selectAllPages = false">
                  <translate translate-context="*/*/Form.Link">Select current page</translate>
                </a>
                <a v-else href="" @click.prevent="selectAllPages = true">
                  <translate translate-context="*/*/Form.Link">Select all rows</translate>
                </a>
              </template>
            </span>
          </div>
          <select name="batch-action" id="batch-action" v-model="currentAction">
            <option value="transformation">
              <translate translate-context="*/*/Form/Action">Apply transformation</translate>
            </option>
            <option value="add_labels">
              <translate translate-context="*/*/Form/Action">Add labels</translate>
            </option>
            <option value="remove_labels">
              <translate translate-context="*/*/Form/Action">Remove labels</translate>
            </option>
            <option value="set_abtest">
              <translate translate-context="*/*/Form/Action">Set A/B Test</translate>
            </option>
          </select>
        </div>
        <div class="field" v-if="currentAction === 'transformation'">
          <label for="batch-transformation">
            <translate translate-context="*/*/*/Voice transformation">Transformation</translate>
          </label>
          <select id="batch-transformation" v-model="actionData" required>
            <option
              :value="transformation.id"
              v-for="transformation in assignableTransformations"
              :key="transformation.id">{{ transformation.name }}</option>
          </select>
        </div>
        <div class="field inline" v-if="currentAction === 'add_labels'">
          <label for="batch-transformation">
            <translate translate-context="*/*/*/Noun">Labels</translate>
          </label>
          <hr class="hidden">
          <template v-for="label in meta.labels" :key="`input-${label}`">
            <input type="checkbox" :id="label" :value="label" v-model="actionData"> &nbsp;
            <label :for="label">{{ label }}</label>
            <br>
          </template>
        </div>
        <div class="field inline" v-if="currentAction === 'remove_labels'">
          <label for="batch-transformation">
            <translate translate-context="*/*/*/Noun">Labels</translate>
          </label>
          <hr class="hidden">
          <template v-for="label in meta.labels" :key="`input-${label}`">
            <input type="checkbox" :id="label" :value="label" v-model="actionData"> &nbsp;
            <label :for="label">{{ label }}</label>
            <br>
          </template>
        </div>
        <div class="field" v-if="currentAction === 'set_abtest'">
          <label for="batch-abtest">
            <translate translate-context="*/*/*">A/B Test</translate>
          </label>
          <select id="batch-abtest" v-model="actionData">
            <option :value="null"><translate translate-context="*/*/*">None</translate></option>
            <option
              :value="abtest.id"
              v-for="abtest in meta.abtests"
              :key="abtest.id">{{ abtest.name }}</option>
          </select>
        </div>
        <field-errors :errors="actionErrors.nonFieldErrors" />
        <app-button :is-loading="batchActionLoading" type="submit">
          <translate translate-context="*/*/Form.Button/Verb">Apply</translate>
        </app-button>
      </form>
      <div class="user-row results-row">
        <strong>
          <input
            type="checkbox"
            @change="toggleCheckAll"
            :disabled="results.results.length === 0"
            :checked="results.results.length > 0 && checked.length === results.results.length">
        </strong>
        <strong>
          <sort-link @updated="filters.o = $event" :current-sort="filters.o" value="name">
            <translate translate-context="*/*/*">User</translate>
          </sort-link>
        </strong>
        <strong>
          <sort-link @updated="filters.o = $event" :current-sort="filters.o" value="last_seen">
            <translate translate-context="*/*/*/Noun">Status</translate>
          </sort-link>
        </strong>
        <strong>
          <sort-link @updated="filters.o = $event" :current-sort="filters.o" value="abtest">
            <translate translate-context="*/*/*">A/B Test</translate>
          </sort-link>
        </strong>
        <strong>
          <sort-link @updated="filters.o = $event" :current-sort="filters.o" value="transformation">
            <translate translate-context="*/*/*/Voice transformation">Transformation</translate>
          </sort-link>
        </strong>
        <strong>
          <translate translate-context="*/*/*/Noun">Labels</translate>
        </strong>
        <strong>
          <sort-link @updated="filters.o = $event" :current-sort="filters.o" value="connected_app">
            <translate translate-context="*/*/*">Connected app</translate>
          </sort-link>
        </strong>
      </div>
      <user-row
        :key="user.session_hash"
        :obj="user"
        :meta="meta"
        @updated="updateRow($event)"
        @filter="filters[$event.name] = $event.value"
        :expand="user.session_hash === expandedRow || user.session_hash === id"
        @expanded="expand(user.session_hash, $event)"
        @deleted="refresh"
        v-for="(user, idx) in results.results">
        <template v-slot:checkbox>
          <input
            type="checkbox"
            @click="toggleCheck($event, user.session_hash, idx)"
            :checked="checked.indexOf(user.session_hash) > -1">

        </template>
      </user-row>
    </section>
    <pagination
      v-if="results && results.count > pageSize"
      @page-changed="filters.page = $event"
      :current="filters.page"
      :paginate-by="pageSize"
      :total="results.count"
      ></pagination>
  </div>
</template>

<script>

import debounce from 'lodash/debounce'
import isEqual from 'lodash/isEqual'
import forOwn from 'lodash/forOwn'
import http from '@/http'

import Pagination from '@/components/Pagination'
import SortLink from '@/components/SortLink'
import UserRow from '@/components/UserRow'

const initialFilters = {
  country: "",
  label: "",
  online: "",
  transformation: "",
  o: '-last_seen',
  page: 1,
  q: '',
  error: '',
  abtest: '',
  audio_tuned: '',
  consent_recording: '',
}

function toQueryString (obj) {
  let d = {}
  forOwn(obj, (val, key) => {
    if (val) {
      d[key] = val
    }
  })
  return d
}
export default {
  props: {
    id: {required: false},
    group: {},
  },
  components: {
    Pagination,
    SortLink,
    UserRow,
  },
  data () {
    let f = {
      errors: {},
      isLoading: false,
      results: null,
      expandedRow: null,
      meta: {countries: [], transformations: [], labels: [], abtests: []},
      filters: {...initialFilters, ...this.$route.query},
      initialFilters: initialFilters,
      checked: [],
      selectAll: false,
      selectAllPages: false,
      pageSize: 30,
      lastCheckedIndex: -1,
      batchActions: [
        "transformation",
        "addLabels",
        "removeLabels",
      ],
      currentAction: "transformation",
      actionData: null,
      actionResult: null,
      actionErrors: {},
      batchActionLoading: false,
    }
    if (this.id) {
      f.filters.q = this.id
    }
    return f
  },
  async created () {    
    let fetch = this.fetchData(this.filters)
    this.meta = (await http.get("it/meta", {params: {group: this.group}})).data
    await fetch
  },
  computed: {
    countries () {
      return []
    },
    labels () {
      return []
    },
    hasFilters () {
      return !isEqual(initialFilters, this.filters)
    },
    allFilters () {
      return {...this.filters}
    },
    ids () {
      return this.results.results.map((o) => { return o.session_hash })
    },
    checkedCount () {
      return this.selectAllPages ? this.results.count : this.checked.length
    },
    searchPlaceholder () {
      return this.$pgettext('*/*/Search.Placeholder', 'IP, ID, session name')
    },
    assignableTransformations () {
      return this.meta.transformations.filter(t => {
        return t.name.toLowerCase() != "manual"
      })
    },
  },
  methods: {
    updateRow (obj) {
      this.results.results = this.results.results.map(o => {
        if (o.session_hash === obj.session_hash) {
          return obj
        }
        return o
      })
    },
    async fetchData(filters) {
      let response
      this.isLoading = true
      this.errors = {}
      this.results = null
      let params = {...filters, page_size: this.pageSize, group: this.group}
      try {
        response = await http.get('it/users', {params})
        this.results = response.data
      } catch (e) {
        this.errors = e.backendErrors
      } finally {
        this.isLoading = false
      }
    },
    expand (id, value) {
      if (value) {
        this.expandedRow = id
      } else {
        this.expandedRow = null
      }
    },
    toggleCheckAll () {
      this.lastCheckedIndex = -1
      if (this.checked.length === this.results.results.length) {
        // we uncheck
        this.checked = []
      } else {
        this.checked = this.results.results.map(i => { return i.session_hash })
      }
    },
    toggleCheck (event, id, index) {
      // quite complicated because we suppose checking individual
      // rows, or range of rows when the shift key is pressed
      let affectedIds = [id]
      let newValue = null
      if (this.checked.indexOf(id) > -1) {
        // we uncheck
        this.selectAll = false
        newValue = false
      } else {
        newValue = true
      }
      if (event.shiftKey && this.lastCheckedIndex > -1) {
        // we also add inbetween ids to the list of affected ids
        let idxs = [index, this.lastCheckedIndex]
        idxs.sort((a, b) => a - b)
        let objs = this.results.results.slice(idxs[0], idxs[1] + 1)
        affectedIds = affectedIds.concat(objs.map((o) => { return o.session_hash }))
      }
      affectedIds.forEach((i) => {
        let checked = this.checked.indexOf(i) > -1
        if (newValue && !checked && this.ids.indexOf(i) > -1) {
          return this.checked.push(i)
        }
        if (!newValue && checked) {
          this.checked.splice(this.checked.indexOf(i), 1)
        }
      })
      this.lastCheckedIndex = index
    },
    async batchAction (action, checked) {
      this.batchActionLoading = true
      this.actionErrors = {}
      this.actionResult = null
      let payload = {
        objects: this.selectAllPages ? "all" : checked,
        action: action,
        data: this.actionData,
        filters: {...this.filters, group: this.group},
      }
      try {
        let response = await http.post('it/users/action', payload)
        this.actionResult = response.data
        this.filters = {...this.initialFilters}
        await this.refresh()
      } catch (e) {
        this.actionErrors = e.backendErrors
      } finally {
        this.batchActionLoading = false
      }
    },
    async refresh () {
      this.expandedRow = null
      this.checked = []
      this.selectAll = false
      this.selectAllPages = false
      if (!this.id) {
        this.$router.push({query: toQueryString(this.filters)})
      }
      window.scrollTo(0, 0);
      await this.fetchData(this.filters)
    }
  },
  watch: {
    allFilters: {
      deep: true,
      handler: debounce(
        async function (n, o) {
          if (n.page === o.page) {
            this.filters.page = 1
          }
          await this.refresh()
        }
      , 250),
    },
    currentAction (v) {
      if (v === "transformation") {
        this.actionData = null
      } else if (["add_labels", "remove_labels"].indexOf(v) > -1) {
        this.actionData = []
      }
    },
    id: {
      async handler (v) {
        this.filters.q = v
        this.results = null
        if (v) {
          this.expandedRow = v
        }
      },
    }
  }
}
</script>
