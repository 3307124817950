
<template>
  <div class="main">
    <section class="v-spaced bordered padded">
      <loading-area v-if="isLoading" :is-loading="isLoading">
        <span><translate translate-context="*/*/Loader">Loading…</translate></span>
      </loading-area>
      <template v-else-if="obj">
        <h2>
          <translate
            :translate-params="{group: obj.name}"
            translate-context="*/*/*"
          >
            Group %{ group }
          </translate>
        </h2>
        <template v-if="isGroupAdmin(obj, $store.state.user)">
          <form @submit.prevent="updateGroup">
            <field-errors :errors="update.errors.nonFieldErrors" />
            <div class="field">
              <label for="group-name">
                <translate translate-context="*/*/*">Name</translate>
              </label>
              <field-errors :errors="update.errors.fieldErrors" field="name" />
              <input required type="text" id="group-name" name="group-name" v-model="update.name">
            </div>
            <app-button :is-loading="update.isUpdating" type="submit">
              <translate translate-context="*/Button/Verb">Update</translate>
            </app-button>
          </form>
          <hr class="my-large">
          <form @submit.prevent="invite">
          <h2>Invite a team member</h2>
            <field-errors :errors="invitation.errors.nonFieldErrors" />
            <div class="stackable row">
              <div class="column">
                <div class="field">
                  <label for="email">
                    <translate translate-context="*/*/*">Email</translate>
                  </label>
                  <field-errors :errors="invitation.errors.fieldErrors" field="email" />
                  <input required type="email" id="email" name="email" v-model="invitation.email">
                </div>
              </div>
              <div class="column">
                <label>
                  <translate translate-context="*/*/*">Role</translate>
                </label>
                <field-errors :errors="invitation.errors.fieldErrors" field="roles" />
                <div class="radio field mt-regular">
                  <input
                    type="radio"
                    name="role_member"
                    id="role_member"
                    value="member"
                    v-model="invitation.role">
                  <label for="role_member">
                    <translate translate-context="*/*/*/Noun">Member</translate>
                  </label>
                  <p class="discrete mt-0">
                    <translate translate-context="*/*/*">Manage Alta Call users, machines and labels within the group</translate>
                  </p>
                </div>
                <div class="radio field">
                  <input
                    type="radio"
                    name="role_admin"
                    id="role_admin"
                    value="admin"
                    v-model="invitation.role">
                  <label for="role_admin">
                    <translate translate-context="*/*/*/Noun">Admin</translate>
                  </label>
                  <p class="discrete mt-0">
                    <translate translate-context="*/*/*">Manage all group resources: Alta Call users, machines, labels, license keys, A/B tests and group members</translate>
                  </p>
                </div>
              </div>
            </div>
            <app-button :is-loading="invitation.isCreating" type="submit">
              <translate translate-context="*/Button/Verb">Invite</translate>
            </app-button>
          </form>
        </template>
        <hr class="my-large">
        <h3>
          <translate
            translate-context="*/*/*"
          >
            Members
          </translate>
        </h3>
        <table>
          <thead>
            <th>
              <translate
                translate-context="*/*/*"
              >
                Name
              </translate>
            </th>
            <th>
              <translate
                translate-context="*/*/*"
              >
                Email
              </translate>
            </th>
            <th>
              <translate
                translate-context="*/*/*"
              >
                Role
              </translate>
            </th>
            <th>
              <translate
                translate-context="*/*/*"
              >
                Joined date
              </translate>
            </th>
            <th v-if="isGroupAdmin(obj, $store.state.user)"></th>
          </thead>
          <tbody>
            <tr v-for="member in obj.members" :key="member.email">
              <td>{{ member.name }}</td>
              <td>{{ member.email }}</td>
              <td>{{ member.role }}</td>
              <td>
                <time :title="member.creation_date" :datetime="member.creation_date">
                  {{ prettyDate(member.creation_date) }}
                </time>
              </td>
              <td v-if="isGroupAdmin(obj, $store.state.user)">
                <button class="link" @click.prevent="remove.toRemove = member; remove.showModal = true">
                  <translate translate-context="*/*/*">Remove…</translate>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <app-modal v-if="remove.showModal && remove.toRemove" @close="remove.showModal = false" :submit-action="removeMember">
          <template v-slot:header>
            <h3>
              <translate
                translate-context="*/*/*"
                :translate-params="{email: remove.toRemove.email, group: obj.name}">Remove %{ email } from group %{ group }</translate>
            </h3>
          </template>
          <p>
            <translate translate-context="*/*/*">The account won't be able to access the group resources anymore.</translate>
          </p>
          <field-errors :errors="remove.errors.nonFieldErrors" />
          <field-errors :errors="remove.errors.fieldErrors" field="email" />

          <template v-slot:action-button>
            <app-button :is-loading="remove.isRemoving" @click.prevent="removeMember">
              <translate translate-context="*/Button/Verb">Remove member</translate>
            </app-button>
          </template>
        </app-modal>

        <h3>
          <translate
            translate-context="*/*/*"
          >
            License keys
          </translate>
        </h3>
        <p>License keys are required during Alta Call installation. They should be considered as password and handled similarly.</p>
        <form
          @submit.prevent="createLicenseKey"
          v-if="isGroupAdmin(obj, $store.state.user)"
          class="mb-large"
        >
          <field-errors :errors="license.errors.nonFieldErrors" />
          <div class="stackable row">
            <div class="column">
              <div class="field">
                <label for="name">
                  <translate translate-context="*/*/*">Name</translate>
                </label>
                <field-errors :errors="license.errors.fieldErrors" field="name" />
                <input
                  required
                  type="text"
                  id="name"
                  name="name"
                  :placeholder="labels.licenseNamePlaceholder"
                  v-model="license.name">
              </div>
            </div>
          </div>
          <app-button :is-loading="license.isCreating" type="submit">
            <translate translate-context="*/Button/Verb">Generate a new license key</translate>
          </app-button>
          <div class="success message" role="alert" v-if="license.latest">
            <p>
              <translate translate-context="*/*/Form.Message">
                Your license key was successfully created.
              </translate>
              <strong>
                <translate translate-context="*/*/Form.Message">
                  Please copy it from the field below and store it in a safe place. You won't be able to see it again.
                </translate>
              </strong>
            </p>
            <input
              type="text"
              :value="license.latest.key"
              style="width: 100%"
              readonly>
          </div>
        </form>
        <table>
          <thead>
            <th>
              <translate
                translate-context="*/*/*"
              >
                Name
              </translate>
            </th>
            <th>
              <translate
                translate-context="*/*/*"
              >
                Key
              </translate>
            </th>
            <th>
              <translate
                translate-context="*/*/*"
              >
                Creation date
              </translate>
            </th>
            <th>
              <translate
                translate-context="*/*/*"
              >
                Machines
              </translate>
            </th>
            <th v-if="isGroupAdmin(obj, $store.state.user)"></th>
          </thead>
          <tbody>
            <tr v-for="l in license.results" :key="l.creation_date">
              <td>{{ l.name }}</td>
              <td class="monospace">{{ l.key }}</td>
              <td>
                <time :title="l.creation_date" :datetime="l.creation_date">
                  {{ prettyDate(l.creation_date) }}
                </time>
              </td>
              <td>{{ l.machines || 0}}</td>
              <td v-if="isGroupAdmin(obj, $store.state.user)">
                <button class="link" @click.prevent="license.toRemove = l; license.showRemoveModal = true">
                  <translate translate-context="*/*/*">Delete…</translate>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <app-modal v-if="license.showRemoveModal && license.toRemove" @close="license.showRemoveModal = false" :submit-action="removeLicenseKey">
          <template v-slot:header>
            <h3>
              <translate
                translate-context="*/*/*"
                :translate-params="{name: license.toRemove.name}">Delete license key %{ name }</translate>
            </h3>
          </template>
          <p>
            <translate translate-context="*/*/*">Alta Call installations using this license key will stop transforming audio and require a new license key.</translate>
          </p>
          <p>
            <strong>
              <translate translate-context="*/*/*">This action is irreversible, please proceed with caution.</translate>
            </strong>
          </p>
          <field-errors :errors="license.removeErrors.nonFieldErrors" />

          <template v-slot:action-button>
            <app-button :is-loading="license.isRemoving" @click.prevent="removeLicenseKey">
              <translate translate-context="*/Button/Verb">Delete license key</translate>
            </app-button>
          </template>
        </app-modal>
      </template>
    </section>
  </div>
</template>

<script>
import http from '@/http'
import {prettyDate} from '@/time'
import {isGroupAdmin} from '@/utils'

export default {
  props: {
    group: {required: true}
  },
  data () {
    return {
      obj: null,
      isLoading: false,
      update: {
        name: '',
        isUpdating: false,
        errors: {},
      },
      invitation: {
        email: '',
        role: 'member',
        isCreating: false,
        errors: {}
      },
      remove: {
        errors: {},
        toRemove: null,
        isRemoving: false,
        showModal: false,
      },
      license: {
        name: '',
        results: [],
        errors: {},
        latest: null,
        isLoading: false,
        isCreating: false,
        removeErrors: {},
        toRemove: null,
        isRemoving: false,
        showRemoveModal: false,
      },
      prettyDate,
      isGroupAdmin,
    }
  },
  async created () {
    await this.fetchData()
    await this.fetchLicenses()
  },
  computed: {
    labels () {
      return {
        licenseNamePlaceholder: this.$pgettext("*/*/Placeholder", "A short name to easily identify your key.")
      }
    }
  },
  methods: {
    async fetchLicenses () {
      this.license.isLoading = true
      let response = await http.get(`it/license-keys`, {params: {group: this.group}})
      this.license.results = response.data.results
      this.license.isLoading = false
    },
    async fetchData () {
      this.isLoading = true
      let response = await http.get(`groups/${this.group}`)
      this.obj = response.data
      this.update.name = this.obj.name
      this.isLoading = false
    },
    async invite () {
      this.invitation.isCreating = true
      this.invitation.errors = {}
      let data = {
        email: this.invitation.email,
        role: this.invitation.role,
      }
      try {
        await http.post(`groups/${this.group}/members`, data)
        this.invitation.email = null
        await this.fetchData({})
      } catch (e) {
        this.invitation.errors = e.backendErrors
      } finally {
        this.invitation.isCreating = false
      }
    },
    async updateGroup () {
      this.update.isUpdating = true
      this.update.errors = {}
      let data = {
        name: this.update.name
      }
      try {
        await http.patch(`groups/${this.group}`, data)
        await this.fetchData({})
        await this.$store.dispatch("fetchGroups", http)
        await this.$store.dispatch("fetchOrganization", http)
      } catch (e) {
        this.update.errors = e.backendErrors
      } finally {
        this.update.isUpdating = false
      }
    },
    async removeMember () {
      if (!this.remove.toRemove) {
        return
      }
      this.remove.isRemoving = true
      this.remove.errors = {}
      let payload = {email: this.remove.toRemove.email}
      try {
        await http.delete(`groups/${this.group}/members`, {data: payload})
        this.remove.toRemove = null
        await this.fetchData({})
      } catch (e) {
        this.remove.errors = e.backendErrors
      } finally {
        this.remove.isRemoving = false
      }
    },
    async createLicenseKey () {
      this.license.isCreating = true
      this.license.errors = {}
      this.license.latest = null
      let data = {
        name: this.license.name,
        group: this.group,
      }

      try {
        let response = await http.post(`it/license-keys`, data)
        this.license.latest = response.data
        this.license.results.unshift(response.data)
        this.license.name = ''
      } catch (e) {
        this.license.errors = e.backendErrors
      } finally {
        this.license.isCreating = false
      }
    },
    async removeLicenseKey () {
      if (!this.license.toRemove) {
        return
      }
      this.license.isRemoving = true
      try {
        await http.delete(`it/license-keys/${this.license.toRemove.id}`)
        this.license.toRemove = null
        await this.fetchLicenses({})
      } catch (e) {
        this.license.removeErrors = e.backendErrors
      } finally {
        this.license.isRemoving = false
      }
    },
  },
  watch: {
    "remove.toRemove" () {
      this.remove.errors = {}
    },
    "license.toRemove" () {
      this.license.removeErrors = {}
    },
  }
}
</script>
